.footer {
  color: black;
  width: 100%;
  /* height: 500px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  column-gap: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.privacy-terms {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.social {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  column-gap: 30px;
}


.cp-container {
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  row-gap: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 20px;
}

.cp-container>p {
  font-weight: normal;
  font-size: 18px;
}

.privacy-terms-link {
  color: grey;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.social-title {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.social-text {
  font-weight: lighter;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.linkedin {
  cursor: pointer;
  fill: black;
  width: 24px;
  height: 24px;
}

.wosbcert {
  width: 125px;
  padding: 20px;
  cursor: pointer;
}


.wosbcert-mobile {
  width: 55%;
  margin-bottom: 15px;
}


@media screen and (max-width: 700px) {
  .footer {
    row-gap: 30px;
  }

  .cp-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .wosbcert {
    padding: 5px;
  }

  .social {
    flex-direction: row;
  }

  .social>div {
    align-items: center;
    justify-content: center;
  }

  .socials,
  .email,
  .offices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}