.services {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, black, rgb(17, 17, 17));
}

.main-services {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 20px;
}

.services-header {
  margin: 75px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-self: flex-start;
  max-width: 500px;
  /* height: 400px; */
}

.services-header>h1 {
  width: 75%;
  min-width: 300px;
  font-size: 40px;
  line-height: 60px;
  font-weight: lighter;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.heatmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.heatmap-image {
  height: 100%;
  border-radius: 10px;
  margin-right: 30px;
  width: 44vw;
  cursor: pointer;
}

.heatmap-subtitle {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
}

/* @media screen and (max-width: 1625px) {
  .heatmap-image {
    width: 55vw;
    min-width: 400px;
  }
} */

.down-right {
  fill: white;
}

@media screen and (max-width: 660px) {

  .main-services {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .services-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 300px;
  }

  .services-header>h1 {
    text-align: center;
    font-size: 28px;
    width: 80%;
    width: 300px;
    line-height: 50px;
    margin: 0;
  }
}

.services-container {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.833);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-size: cover;
}

.services-col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.service-item {
  padding: 15px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  transition: 0.25s ease-in-out;
}

.service-item:hover {
  background-color: rgba(0, 0, 0, 0.444);
}

.service-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 75%;
}

.service-item-title {
  font-weight: bold;
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.service-item-description {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width: 900px) {

  .services-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .service-item {
    /* min-width: 300px; */
    border-radius: 5px;
  }

  .services-container {
    flex-direction: column;
    row-gap: 20px;
    /* min-width: 300px; */
  }


  .services-col {
    min-width: 350px;
    row-gap: 20px;
  }

  .service-item-container {
    width: 100%;
  }
}

.snippets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;
  margin-bottom: 0;
}

.snippets-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-bottom: 60px;
  /* width: 500px; */
}

.snippets-header-title {
  font-size: 44px;
  margin: 5px;
  text-align: center;
  font-weight: lighter;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.snippets-header-description {
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
}


@media screen and (max-width: 600px) {
  .snippets-header {
    width: 100%;
  }
}

.snippets-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 150px;
  flex-wrap: wrap;
  row-gap: 50px;
}


.snippet-item-container {
  width: min(max(450px, 100%), 300px);
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
  background-color: rgb(20, 20, 20);
  border-radius: 7px;
  transition: 0.25s ease-in-out;
}

.snippet-item-container:hover {
  background-color: rgb(30, 30, 30);
}

.snippet-item-image {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px;
}

.snippet-item-title {
  font-size: 18px;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.snippet-item-snippet {
  font-weight: lighter;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contact-us-button-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-button {
  cursor: pointer;
  width: 200px;
  height: 60px;
  border: none;
  background-color: var(--primary);
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
}

.quote-container {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-inner-container {
  width: 90%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  row-gap: 20px;
}

.quote-text,
.quote-author {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.353);
}

.quote-text {
  font-weight: lighter;
  font-family: Georgia;
}

.quote-author {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 19px;
}

@media screen and (max-width: 690px) {
  .quote-text {
    text-align: center;
  }

  .quote-author {
    width: 100%;
    text-align: center;
  }
}