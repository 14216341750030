.who-we-are {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, black, rgb(24, 24, 24));
}

.who-we-are .body {
  width: 40%;
  min-width: 350px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 30px;
  padding-left: 30px;
  border-left: 2px solid var(--primary);
  font-size: 17px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 30px;
}

.who-we-are-description .title {
  margin: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.company-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 5px;
  font-size: 40px;
  margin-bottom: 20px;
}

.who-we-are-description {
  margin-left: 50px;
}

@media screen and (max-width: 600px) {
  .company-title {
    font-size: 30px;
  }

  .title {
    font-size: 16px;
  }

  .who-we-are-description>.body {
    font-size: 15px;
  }
}

.tigerCity-image {
  max-height: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.approach {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  column-gap: 50px;
}



.approach-title {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 25px;
  margin: 30px;
  margin-top: 45px;
}

.our-approach-c1,
.our-approach-c2 {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-items: center;

}

.our-approach-c1 {
  width: 60%;
  /* margin-left: 60px; */
  row-gap: 50px;
}

.our-approach-c2 {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 40px;
  /* margin-right: 60px; */
}

.approach-large {
  width: 80%;
  max-width: 600px;
  font-size: calc(20px + 6 * ((100vw - 320px) / 680));
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.approach-small {
  width: 70%;
  max-width: 450px;
  font-size: 17px;
  line-height: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-bottom: 40px;
  border-bottom: 2px solid white;
  font-size: max(min(calc(17px + 6 * ((100vw - 1000px) / 680)), 17px), 15px);
  /* margin-left: 30px; */
}

.core-values {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  margin-bottom: 25px;
}

.core-value {
  /* margin: 20px 0 20px 0; */
  border-left: 2px solid var(--primary);
  padding-left: 20px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media screen and (max-width: 1000px) {
  .who-we-are {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .who-we-are-description {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .approach {
    flex-direction: column;
    padding: 0 !important;
  }

  .core-values {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }

  .our-approach-c1,
  .our-approach-c2 {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


}

.core-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 30px;
}

.core-description {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}


.leadership {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to right, black, rgb(20, 20, 20));
  padding: 20px;
}

.leadership-heading {
  padding: 10px;
}

.leadership-heading>h1 {
  font-size: 35px;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.c-cuite-leaders {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  column-gap: 20px;
  /* column-gap: 50px; */
}

.leader {
  padding: 30px;
  background-color: rgb(15, 15, 15);
  border-radius: 10px;
  max-width: 400px;
  min-height: 962px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}

.leader:hover {
  background-color: rgb(20, 20, 20);
}

.leader-name,
.leader-position,
.leader-statement {
  /* align-self: flex-start; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
}

.leader-statement {
  margin-top: 10px;
}

.leader-name {
  font-size: 20px;
}

.leader-position {
  font-weight: 600;
}

.leader-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

@media screen and (max-width: 1000px) {
  .leader-statement {
    text-align: start;
  }

  .leader {
    height: fit-content;
    min-height: unset;
  }
}