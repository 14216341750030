.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* height: calc(100vh - 200px);
   */
  height: fit-content;
  min-height: calc(100vh - 200px);
  background-image: linear-gradient(to left, black, rgb(19, 19, 19));
  row-gap: 20px;
}

.contact-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  row-gap: 30px;
  margin-bottom: 30px;
}

.contact-ceo {
  width: 200px;
  object-fit: cover;
  height: 200px;
  margin-top: 30px;
  border-radius: 15px;
  /* margin: 30px; */
}

.contact-ceo-name {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 20px;
}


.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(18, 18, 18);
  height: 250px;
  width: 85%;
  max-width: 350px;
  min-width: 300px;
  border-radius: 5px;
}

.contact-mail {
  color: white;
  font-size: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contact-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contact-linkedin {
  fill: #0072b1;
  width: 70px;
  height: 70px;
  cursor: pointer;
}


/* .contact-container { */
/* width: 100%; */
/* min-height: 75vh; */
/* min-height: 78vh; */
/* height: auto; */
/* height: 100%; */
/* background-image: linear-gradient(to left, black, rgb(19, 19, 19)); */
/* background-color: white; */
/* flex-grow: 1;
  background-color: rgb(24, 24, 24);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

/* .contact-header {
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: lighter;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

/* .contact-form { */
/* height: 500px; */
/* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 30px; */
/* padding: 50px; */
/* padding-top: 20px; */
/* } */
/* 
.contact-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
} */

/* .contact-input {
  background-color: rgb(28, 28, 28);
  width: 400px; */
/* height: 40px; */
/* color: white;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid white;
} */

/* 
@media screen and (max-width: 600px) {
  .contact-input {
    width: 80%;
  }
}

.textarea-message {
  resize: vertical;
  width: 80%;
  min-height: 200px;
  background-color: rgb(28, 28, 28);
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid white;
}

.contact-submit-button {
  cursor: pointer;
  width: 200px;
  height: 75px;
  border: none;
  background-color: var(--primary);
  color: white;
  font-size: 18px;
  font-weight: lighter;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: 0.2s ease-in-out;
  margin-bottom: 25px;
}

.contact-submit-button:hover,
.contact-submit-button:active {
  background-color: var(--secondary);
} */