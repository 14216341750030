.terms-of-use {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  row-gap: 30px;
}

.terms-of-use-policy>h1 {
  font-size: 50px;
}

.terms-of-use-content {
  width: 50%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.terms-of-use-item-title {
  font-size: 30px;
}

.terms-of-use-item-description {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}