.navbar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  transition: 0.25s ease-in-out;
}

.nav-hidden {
  opacity: 0;
}

.navbar-spacer {
  width: 100%;
  height: 110px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
  cursor: pointer;
  margin-left: 30px;
}

.company-name {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 38px;
}


.company-logo {
  height: 110px;
  /* width: ; */
  cursor: pointer;
}

.nav-options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 12px;
  column-gap: 60px;
  margin-right: 50px;
}

.nav-option {
  text-decoration: none;
  color: white;
  font-family: 'Assistant';
  font-size: 21px;
  font-weight: lighter;
  transition: 0.2s ease-in-out;
}

.nav-option:hover {
  color: var(--primary);
}

.active-link {
  color: var(--primary);
}

@media screen and (max-width: 1250px) {
  .company-name {
    font-size: 30px;
  }

  .nav-option {
    font-size: 17px;
  }
}


.menu-panel {
  position: fixed;
  border-radius: 7px;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.482);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
  transition: 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 100;
}


.menu-panel>a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid var(--sbcc-red);

  font-weight: bold;
  font-size: 24px;
  user-select: none;
}

.menu-panel>a:hover {
  background-color: rgba(19, 19, 19, 1);
}

.hamburger-react {
  position: fixed;
  z-index: 101;
  margin-right: 50px;
}

.menu-panel.closed {
  transform: translateX(150%);
  opacity: 0%;
  box-shadow: none;
}


.menu-panel.open {
  transform: none;
}

@media screen and (max-width: 600px) {
  .company-name {
    font-size: 22px !important;
  }

  .company-logo {
    align-self: center;
    justify-self: center;
    height: 90px;
    width: px;
    /* margin-left: 5px; */
    /* width: 80px; */
  }

  .navbar-spacer {
    height: 90px;
  }

  .hamburger-react {
    margin-right: 10px;
  }
}