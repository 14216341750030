.not-found {
    width: 100%;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
    background-image: linear-gradient(left to right, rgb(0, 0, 0), rgb(20, 20, 20));
}

.error-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    padding: 10px;
}

.error-title {
    text-align: center;
    font-size: 80px;
    font-weight: lighter;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.error-description {
    text-align: center;
    font-size: 27px;
    font-weight: lighter;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.error-button {
    cursor: pointer;
    width: 200px;
    height: 60px;
    border: none;
    background-color: var(--primary);
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
}