.indicators {
  margin-top: -10px;
  margin-bottom: -20px;
  padding: 0;
}

.indicator {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin: 0 5px 0 5px;
  border: 2px solid white;
  position: relative;
  margin-top: -50px;
}

.indicator.active {
  background-color: white;
}


.slide-image {
  width: 100vw;
  height: 100%;
}

.slide-image>div {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.675);
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 60vh;
  transition: 0.25s ease-in-out;
}

.slide-image-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.slide-image-header {
  font-weight: normal;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.334);
  font-size: 40px;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* stroke: black; */
}

.slide-image-description {
  text-align: center;
  margin: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.442);
}

.slide-image-learn-more {
  cursor: pointer;
  width: 200px;
  height: 60px;
  border: none;
  background-color: var(--primary);
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
}

.slide-image-learn-more:hover,
.slide-image-learn-more:active {
  background-color: var(--secondary);
}

@media screen and (max-width: 600px) {
  .slide-image-header {
    font-size: 33px;
  }
}

.clients-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  row-gap: 20px;
}

.clients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  row-gap: 40px;
}

.clients-title {
  font-family: Georgia;
  font-size: 35px;
  font-weight: lighter;
}

.clients>img {
  width: 150px;
}