h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  line-height: 30px;
}

:root {
  --large-text-font: normal normal normal 67px/1.4em georgia, palatino, 'book antiqua', 'palatino linotype', serif;
  --medium-text-font: normal normal normal 46px/1.4em georgia, palatino, 'book antiqua', 'palatino linotype', serif;
  --small-text-font: normal normal normal 17px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;

  --font-color: white;
  --background: black;
  --primary: #f96714;
  --secondary: rgb(175, 97, 24);
}

body {
  margin: 0;
  background-color: black;
  color: white;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}